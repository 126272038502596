@font-face {
  font-family: 'Ubuntu Bold';
  src: url('../assets/fonts/Ubuntu-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'Ubuntu Medium';  src: url('../assets/fonts/Ubuntu-Medium.ttf') format('ttf');
}

@font-face {
  font-family: 'Ubuntu Regular';  src: url('../assets/fonts/Ubuntu-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Ubuntu Light';  src: url('../assets/fonts/Ubuntu-Light.ttf') format('ttf');
}

body {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu Regular", sans-serif;
  background-color: #fff;  
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Ubuntu Bold", sans-serif;
  margin: 0;
}

h1 {
  font-size: 30px;
  font-weight: 700;
}

h2 {
  font-size: 28px;
  font-weight: 700;
}

h3 {
  font-size: 26px;
  font-weight: 600;
} 

h4 {
  font-size: 24px;
  font-weight: 600;
}

.subtitle {
  font-size: 18px;
  font-weight: 600;
}

.text-black {
  color: #000000;
}

.text-gray {
  color: #8D8D8D;
}

.text-left {
  text-align: left;
  align-items: flex-start!important;
}

.text-right {
  text-align: right;
  align-items: flex-end!important;
}

.text-center {
  text-align: center;
  align-items: center!important;
}

.text-small {
  font-size: small;
}

.text-medium {
  font-size: medium;
}

.text-large {
  font-size: large;
}

.layout {
  min-height: 100vh;
}

.mt-1 {
  margin-top: 1rem!important;
}

.mt-2 {
  margin-top: 2rem!important;
}

.mt-3 {
  margin-top: 3rem!important;
}

.mt-4 {
  margin-top: 4rem!important;
}

.mt-5 {
  margin-top: 5rem!important;
}

.mb-1 {
  margin-bottom: 1rem!important;
}

.mb-2 {
  margin-bottom: 2rem!important;
}

.mb-3 {
  margin-bottom: 3rem!important;
}

.mb-4 {
  margin-bottom: 4rem!important;
}

.mb-5 {
  margin-bottom: 5rem!important;
}

.w-25 {
  width: 25%!important;
}

.w-50 {
  width: 50%!important;
}

.w-75 {
  width: 75%!important;
}

.w-100 {
  width: 100%!important;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Tela ocupará 100% da altura do navegador */
}

.form-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.half-width {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-side {
  background-image: url("../assets/images/bg-1.jpg");
  background-size: cover;
  background-position: center;
  padding: 0%;
}

.right-side {
  background-color: #fff; /* Fundo branco para a metade direita */
  padding: 0%;
}

.right-content {
  color: #FFFFFF;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  padding: 15%;
}

.left-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 25%;
  margin-top: 0;
}

.logo img {
  width: 100%;
}

.ant-input {
  border-radius: 0px;
  height: 36px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-color: #000000;
}

.ant-input:focus {
  border-color: #000000!important;
  box-shadow: none!important;
}

.ant-input:hover {
  border-color: #000000!important;
  border-inline-end-width: 1px;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  border-color: #000;
}

.ant-input-group-wrapper-status-error .ant-input:hover {
  border-color: #000!important;
}

.ant-btn {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  border-color: #000000;
}

.ant-btn-default {
  color: #000000;
  border-color: #000000;
}

.ant-btn-default:hover {
  color: #333333!important;
  border-color: #333333!important;
}

.ant-btn-primary {
  color: #fff;
  background-color: #000000;
}

.ant-btn-primary:hover {
  color: #fff;
  background-color: #333333!important;
}

.btn-icon {
  width: 18px!important;
  margin-right: 10px;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1; /* Preenche o espaço disponível verticalmente */
}

.right-content .text-container p {

  font-size: large;
}

.bottom-text {
  text-align: left;
  margin-top: auto;
  font-weight: 500;
}

.form-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.success-image {
  margin-bottom: 30px;
}

.ant-input-group-addon {
  color: #999999!important;
  border-color: #000000!important;
  border-top: none!important;
  border-left: none!important;
  background: none!important;
  border-radius: 0!important;
  font-weight: 700;
  font-size: large;
}

.ant-input-group-wrapper-status-error .ant-input-group-addon {
  color: #000!important;
  border-color: #000!important;
  border-top: none!important;
  border-left: none!important;
  background: none!important;
  border-radius: 0!important;
}

.btn-group {
  margin-top: 60px;
}

/* Restante dos estilos */

.next-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  width: 100%;
}

.next-header {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-height: 50%;
  padding: 10vh 5% 0;
  background-image: url('../assets/images/bg-2.jpg');
  background-size: cover;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-next {
  height: 80px;
}

.tipzar-next {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
}

.next-form-content {
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 2vh 20%;
  width: 100%;
}

.next-form-content form {
  width: 100%;
}

.form-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2vh;
}

.form-item-half {
  width: 48%;
}

/* Mobile view */
@media only screen and (max-width: 768px) {
  .form-container {
    flex-direction: column;
    height: auto;
  }

  .half-width {
    width: 100%;
    height: auto;
  }

  .right-content {
    padding: 10%;
  }

  .logo {
    width: 50%;
  }

  .form-content {
    padding: 20px;
  }

  .btn-group {
    margin-top: 20px;
  }
}

@media (max-width: 768px) { /* Tablets e dispositivos menores */
  .content {
    height: auto;
    /* padding: 2rem 1rem; */
  }

  .form-container {
    /* flex-direction: column-reverse; */
  }

  .next-form-content {
    padding: 2vh 5%;
  }

  .form-row {
    flex-direction: column;
  }

  .form-item-half {
    width: 100%;
  }

  .half-width {
    width: 100%;
  }

  .left-side {
    min-height: 300px; /* Ou o valor que você achar adequado */
    padding: 1rem;
  }

  .right-content {
    padding: 1rem;
  }

  .right-side {
    padding: 1rem;
  }
}